import { __ } from 'i18n';

export function getPrettyStatus (stat) {
       if (stat === 'chat') {
        return __('Online');
    } else if (stat === 'dnd') {
        return __('Do Not Disturb');
    } else if (stat === 'xa') {
        return __('Offline');
    } else if (stat === 'away') {
        return __('Away');
    } else if (stat === 'offline') {
        return __('Offline');
    } else {
        return __(stat) || __('Online');
    }
}
