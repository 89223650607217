import { api } from '@converse/headless/core';
import { CustomElement } from './element.js';
import { html } from 'lit';


export class ConverseBrandLogo extends CustomElement {

    render () { // eslint-disable-line class-methods-use-this
        const is_fullscreen = api.settings.get('view_mode') === 'fullscreen';
        return html`
        <a class="brand-heading" href="https://glacier.chat/" target="_blank" rel="noopener">
            <span class="brand-name-wrapper ${is_fullscreen ? 'brand-name-wrapper--fullscreen' : ''}">
            <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" version="1.1" viewBox="0 0 361 365" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:serif="http://www.serif.com/" class="converse-svg-logo">
            <g transform="translate(-126.22 -214.05)">
                <g serif:id="Layer 1">
                    <g transform="translate(126.24 214.2)">
                        <path d="m0 156.12c0-51.71 2e-3 -103.42-0.02-155.13 0-0.848 0.11-1.134 1.072-1.134 119.3 0.028 238.6 0.028 357.9 0 0.962 0 1.072 0.287 1.071 1.134-0.021 51.711-0.019 103.42-0.019 155.13-0.448 0.144-0.666-0.189-0.878-0.462-0.69-0.89-1.523-1.642-2.317-2.43-7.126-7.068-14.086-14.303-21.129-21.454-4.265-4.33-8.644-8.553-12.794-12.991-4.729-5.058-9.82-9.754-14.548-14.802-3.968-4.235-8.237-8.166-12.185-12.41-4.824-5.187-10.047-9.976-14.885-15.139-3.584-3.826-7.444-7.378-10.997-11.223-4.998-5.406-10.427-10.385-15.456-15.756-1.421-1.52-2.866-3.013-4.339-4.48-7.057-7.025-13.983-14.181-20.969-21.275-7.237-7.349-14.47-14.707-21.661-22.103-0.811-0.835-1.647-1.207-2.834-1.205-16.68 0.036-33.359 0.033-50.039 3e-3 -1.13-2e-3 -1.946 0.326-2.745 1.129-5.723 5.759-11.377 11.585-17.045 17.395-4.132 4.236-8.419 8.321-12.447 12.652-4.442 4.778-9.264 9.177-13.703 13.947-3.9 4.189-8.067 8.106-12.029 12.228-8.232 8.561-16.645 16.945-24.919 25.462-4.431 4.561-9.042 8.945-13.378 13.592-3.55 3.803-7.381 7.326-10.907 11.141-4.916 5.32-10.265 10.207-15.194 15.509-1.144 1.231-2.306 2.442-3.499 3.623-6.907 6.836-13.608 13.873-20.439 20.782-2.472 2.5-4.946 5.004-7.4 7.524-0.34 0.349-0.622 0.875-1.264 0.743" fill="#fefefe" fill-rule="nonzero"/>
                    </g>
                    <g transform="translate(126.24 422.85)">
                        <path d="m0-52.533c0.923-0.582 1.559-1.464 2.308-2.226 14.292-14.54 28.58-29.084 42.863-43.633 14.954-15.232 29.9-30.472 44.851-45.707 8.708-8.874 17.426-17.739 26.129-26.617 12.172-12.416 24.336-24.837 36.49-37.27 0.404-0.414 0.802-0.556 1.359-0.556 17.315 0.012 34.632 0.019 51.948-0.016 0.93-2e-3 1.354 0.554 1.866 1.075 10.458 10.632 20.911 21.269 31.363 31.908 22.275 22.673 44.552 45.348 66.825 68.023 17.454 17.769 34.904 35.54 52.355 53.311 0.553 0.564 1.095 1.139 1.643 1.708v52.8c-0.254 0.455-0.565 0.861-0.938 1.232-7.403 7.354-14.678 14.835-21.986 22.281-8.903 9.072-17.812 18.139-26.722 27.204-3.222 3.277-6.522 6.483-9.648 9.852-5.659 6.096-11.767 11.749-17.474 17.795-1.399 1.482-2.845 2.919-4.254 4.391-6.715 7.019-13.666 13.806-20.42 20.787-1.805 1.866-3.608 3.738-5.451 5.571-6.886 6.851-13.652 13.824-20.463 20.749-8.045 8.177-16.007 16.438-24.125 24.544-0.536 0.534-1.026 1.143-1.882 1.171-0.478 0.11-0.958 0.057-1.436 0.057-17.21 3e-3 -34.42 1e-3 -51.63 9e-3 -0.47 0-0.959-0.123-1.411 0.117h-0.48c-4.295-4.242-8.599-8.475-12.754-12.855-4.073-4.295-8.399-8.342-12.496-12.611-8.832-9.199-17.846-18.218-26.783-27.313-3.052-3.106-6.136-6.185-9.155-9.325-8.976-9.339-18.214-18.42-27.2-27.748-4.771-4.953-9.771-9.677-14.451-14.715-3.649-3.927-7.612-7.545-11.274-11.453-5.005-5.343-10.373-10.326-15.387-15.651-3.401-3.612-7.05-6.975-10.401-10.624-3.52-3.831-7.299-7.398-10.926-11.118-0.34-0.349-0.609-0.734-0.853-1.147v-54z" fill="#21a1cc" fill-rule="nonzero"/>
                    </g>
                    <g transform="translate(333.37 423.62)">
                        <path d="m0 154.79c2.034-1.683 3.76-3.676 5.606-5.548 8.335-8.451 16.658-16.916 24.979-25.381 11.374-11.57 22.744-23.144 34.113-34.718 12.069-12.288 24.133-24.577 36.2-36.864 8.762-8.921 17.529-17.838 26.288-26.76 8.062-8.214 16.116-16.436 24.179-24.648 0.474-0.482 1-0.913 1.502-1.367 0 51.559-3e-3 103.12 0.023 154.68 0 0.9-0.203 1.103-1.103 1.102-50.68-0.025-101.36-0.022-152.04-0.022-0.146-0.282 0.107-0.349 0.253-0.474" fill="#fefefe" fill-rule="nonzero"/>
                    </g>
                    <g transform="translate(126.24 578.9)">
                        <path d="m0-154.58c2.963 2.62 5.63 5.541 8.401 8.354 15.314 15.546 30.589 31.132 45.879 46.702 11.399 11.607 22.801 23.211 34.2 34.817 11.902 12.117 23.806 24.232 35.703 36.354 8.819 8.984 17.631 17.975 26.441 26.969 0.401 0.41 0.946 0.723 1.056 1.364-50.2 0-100.4-3e-3 -150.6 0.022-0.9 1e-3 -1.103-0.202-1.102-1.103 0.024-51.16 0.022-102.32 0.022-153.48" fill="#fefefe" fill-rule="nonzero"/>
                    </g>
                    <g transform="translate(333.37 578.88)">
                        <path d="m0-0.474c-0.084 0.157-0.169 0.315-0.253 0.474h-54.72c0.436-0.434 0.986-0.231 1.48-0.232 17.164-0.012 34.328-0.011 51.491-5e-3 0.682 1e-3 1.35-0.029 2.002-0.237" fill="#38aad1" fill-rule="nonzero"/>
                    </g>
                    <g transform="translate(478.74 570.35)">
                        <path d="m0-189.98v1.92c1e-3 12.201-7e-3 24.4 0.019 36.6 2e-3 0.909-0.267 1.561-0.91 2.214-19.976 20.29-39.934 40.599-59.894 60.905-8.744 8.898-17.489 17.794-26.231 26.695-14.737 15.007-29.472 30.017-44.209 45.026-5.181 5.277-10.373 10.543-15.529 15.844-0.669 0.687-1.023 0.776-1.763 0.02-13.666-13.988-27.364-27.943-41.059-41.901-11.819-12.045-23.644-24.084-35.463-36.128-11.649-11.872-23.294-23.748-34.943-35.62-8.764-8.932-17.532-17.859-26.295-26.791-1.96-1.997-3.888-4.028-5.87-6.002-0.558-0.556-0.676-0.868-0.041-1.51 6.55-6.623 13.072-13.275 19.568-19.951 0.655-0.672 0.985-0.742 1.682-0.029 12.19 12.464 24.412 24.898 36.624 37.341 14.649 14.927 29.292 29.859 43.942 44.784 11.681 11.901 23.369 23.793 35.054 35.69 2.858 2.91 5.729 5.809 8.554 8.751 0.578 0.601 0.871 0.638 1.487 0.011 18.777-19.16 37.578-38.294 56.374-57.436 8.596-8.756 17.186-17.518 25.784-26.274 11.961-12.181 23.931-24.353 35.887-36.539 8.762-8.932 17.507-17.883 26.264-26.822 0.219-0.223 0.484-0.401 0.968-0.798" fill="#fefefe" fill-rule="nonzero"/>
                    </g>
                    <g transform="translate(322.35 274.66)">
                        <path d="m0 297.3c-1.8 0-3.345-2e-3 -4.89 0-11.559 0.016-23.118 0.025-34.678 0.062-0.86 3e-3 -1.476-0.23-2.096-0.863-24.318-24.825-48.656-49.63-72.992-74.438-14.671-14.956-29.343-29.912-44.011-44.872-9.323-9.507-18.634-19.026-27.972-28.517-0.591-0.601-0.591-0.899 0.01-1.511 18.391-18.703 36.759-37.429 55.132-56.151 8.596-8.76 17.191-17.521 25.791-26.278 11.71-11.925 23.429-23.842 35.133-35.771 9.213-9.39 18.412-18.792 27.61-28.197 0.494-0.506 0.782-0.831 1.478-0.104 6.192 6.472 12.422 12.908 18.681 19.314 0.647 0.661 0.611 0.957-9e-3 1.586-13.081 13.27-26.136 26.564-39.197 39.853-11.744 11.949-23.491 23.893-35.233 35.843-14.685 14.945-29.366 29.892-44.047 44.841-1.625 1.653-3.197 3.359-4.864 4.969-0.654 0.631-0.512 0.972 0.047 1.533 4.769 4.785 9.522 9.586 14.26 14.399 8.972 9.114 17.93 18.24 26.894 27.361 8.627 8.778 17.255 17.555 25.882 26.335 9.077 9.236 18.155 18.472 27.231 27.711 8.768 8.927 17.529 17.86 26.3 26.786 8.292 8.439 16.593 16.872 24.887 25.309 0.167 0.17 0.302 0.368 0.653 0.8" fill="#fefefe" fill-rule="nonzero"/>
                    </g>
                    <g transform="translate(133.78 223.26)">
                        <path d="m0 189.95c-0.294-0.632-0.147-1.191-0.147-1.729-0.01-12.281 0-24.56-0.027-36.839-2e-3 -0.902 0.233-1.552 0.882-2.209 9.407-9.535 18.786-19.098 28.17-28.656 8.767-8.929 17.532-17.859 26.296-26.79 11.904-12.128 23.809-24.255 35.71-36.385 14.534-14.815 29.065-29.633 43.596-44.451 4.003-4.082 8.018-8.151 11.983-12.269 0.599-0.621 0.88-0.502 1.399 0.028 12.95 13.22 25.907 26.433 38.875 39.635 5.602 5.704 11.24 11.373 16.845 17.074 14.742 14.994 29.474 29.998 44.211 44.997 8.744 8.898 17.49 17.793 26.232 26.692 5.913 6.018 11.809 12.052 17.745 18.047 0.728 0.735 0.749 1.121-0.018 1.893-6.336 6.383-12.629 12.812-18.893 19.268-0.69 0.71-1.012 0.665-1.671-9e-3 -11.694-11.938-23.415-23.849-35.128-35.768-8.77-8.925-17.539-17.852-26.307-26.779-8.88-9.042-17.759-18.086-26.639-27.129-8.824-8.987-17.652-17.971-26.474-26.959-3.22-3.281-6.438-6.563-9.633-9.868-0.486-0.504-0.757-0.607-1.314-0.038-8.244 8.433-16.515 16.841-24.779 25.254-12.273 12.492-24.544 24.987-36.818 37.477-11.545 11.749-23.093 23.493-34.638 35.241-8.938 9.096-17.88 18.189-26.81 27.294-7.252 7.393-14.487 14.802-21.733 22.199-0.268 0.273-0.583 0.499-0.915 0.779" fill="#fefefe" fill-rule="nonzero"/>
                    </g>
                    <g transform="translate(290 518.32)">
                        <path d="m0-296.8h6.176c10.878-2e-3 21.758 5e-3 32.636-0.024 0.744-2e-3 1.27 0.209 1.775 0.723 10.272 10.469 20.553 20.927 30.831 31.389 5.907 6.012 11.813 12.023 17.716 18.038 5.71 5.82 11.415 11.645 17.125 17.465 5.959 6.074 11.922 12.144 17.883 18.217 5.767 5.875 11.531 11.753 17.3 17.626 5.933 6.042 11.874 12.076 17.807 18.118 8.764 8.926 17.522 17.858 26.287 26.783 1.548 1.578 1.549 1.561-0.025 3.157-7.548 7.653-15.099 15.301-22.634 22.967-8.657 8.809-17.294 17.639-25.946 26.452-11.846 12.066-23.7 24.122-35.547 36.188-8.738 8.898-17.467 17.806-26.204 26.707-8.793 8.956-17.592 17.906-26.383 26.863-1.791 1.825-3.589 3.645-5.326 5.52-0.536 0.58-0.842 0.64-1.415 0.061-6.326-6.398-12.667-12.781-19.029-19.143-0.609-0.607-0.548-0.95 0.025-1.529 7.568-7.644 15.116-15.307 22.658-22.976 11.884-12.086 23.759-24.181 35.639-36.271 8.686-8.839 17.373-17.674 26.061-26.511 11.823-12.027 23.647-24.054 35.468-36.084 1.065-1.083 2.077-2.22 3.175-3.266 0.541-0.515 0.524-0.824 0.011-1.345-5.945-6.04-11.863-12.104-17.8-18.152-11.898-12.123-23.805-24.24-35.707-36.359-5.824-5.93-11.648-11.861-17.474-17.79-11.761-11.971-23.566-23.902-35.274-35.926-10.314-10.593-20.698-21.118-31.114-31.611-2.729-2.749-5.425-5.531-8.137-8.296-0.225-0.228-0.454-0.45-0.558-0.991" fill="#fefefe" fill-rule="nonzero"/>
                    </g>
                    <g transform="translate(354.04 395.95)">
                        <path d="m0 0.839c-0.081 0.527-0.462 0.741-0.729 1.013-4.899 4.993-9.813 9.97-14.721 14.956-9.445 9.594-18.886 19.192-28.335 28.782-0.636 0.646-1.129 1.837-1.97 1.758-0.677-0.064-1.271-1.103-1.884-1.728-12.368-12.611-24.734-25.224-37.098-37.84-2.321-2.368-4.62-4.757-6.96-7.107-0.566-0.566-0.498-0.889 0.051-1.446 10.166-10.289 20.312-20.598 30.451-30.914 4.622-4.703 9.237-9.413 13.813-14.16 0.715-0.741 1.104-0.678 1.789 0.025 8.98 9.207 17.988 18.386 26.99 27.571 5.733 5.848 11.47 11.692 17.2 17.542 0.498 0.509 0.958 1.055 1.403 1.548" fill="#fefefe" fill-rule="nonzero"/>
                    </g>
                    <g transform="translate(366.74 332.92)">
                        <path d="m0 50.916c4e-3 0.344-0.194 0.5-0.358 0.669-2.987 3.058-5.991 6.099-8.953 9.182-0.702 0.731-0.967 0.051-1.307-0.294-4.317-4.386-8.614-8.789-12.926-13.18-4.422-4.503-8.855-8.996-13.279-13.497-8.99-9.15-17.979-18.301-26.966-27.455-2.352-2.395-4.726-4.769-7.024-7.215-0.686-0.729-1.044-0.764-1.766-0.023-7.08 7.272-14.196 14.508-21.313 21.744-7.675 7.804-15.366 15.593-23.044 23.395-5.854 5.948-11.704 11.901-17.543 17.864-3.266 3.335-6.511 6.69-9.756 10.045-0.434 0.449-0.791 0.423-1.212-9e-3 -2.897-2.98-5.789-5.967-8.72-8.914-0.551-0.555-0.182-0.777 0.165-1.128 8.873-8.991 17.743-17.985 26.612-26.98 15.911-16.14 31.823-32.28 47.733-48.421 2.552-2.59 5.119-5.165 7.634-7.791 0.566-0.592 0.896-0.567 1.434-6e-3 9.162 9.533 18.489 18.902 27.779 28.309 8.677 8.786 17.291 17.632 25.94 26.445 5.456 5.56 10.925 11.108 16.385 16.664 0.191 0.195 0.348 0.426 0.485 0.596" fill="#fefefe" fill-rule="nonzero"/>
                    </g>
                    <g transform="translate(316.17 345.98)">
                        <path d="m0 137.32c0.069 0.395-0.205 0.487-0.36 0.646-2.655 2.715-5.322 5.418-7.966 8.142-0.453 0.467-0.759 0.395-1.18-0.035-8.805-9.004-17.616-18.004-26.434-26.994-5.57-5.679-11.159-11.338-16.731-17.013-8.932-9.097-17.859-18.196-26.783-27.299-3.804-3.882-7.581-7.79-11.411-11.646-0.624-0.628-0.527-0.974 0.029-1.535 5.263-5.311 10.508-10.639 15.752-15.969 8.969-9.116 17.936-18.235 26.901-27.357 8.793-8.947 17.59-17.892 26.358-26.864 0.638-0.652 0.92-0.634 1.549 0.031 2.662 2.815 5.388 5.572 8.129 8.311 0.622 0.622 0.783 0.957 0.051 1.699-15.581 15.791-31.131 31.613-46.679 47.437-4.372 4.449-8.699 8.941-13.096 13.365-0.72 0.725-0.682 1.077 0.013 1.78 8.879 8.978 17.726 17.988 26.577 26.991 8.767 8.919 17.533 17.838 26.294 26.763 5.935 6.047 11.862 12.102 17.787 18.16 0.444 0.454 0.834 0.961 1.2 1.387" fill="#fefefe" fill-rule="nonzero"/>
                    </g>
                    <g transform="translate(400.36 472.44)">
                        <path d="m0-75.013c-4e-3 0.412-0.275 0.55-0.466 0.744-5.965 6.076-11.926 12.155-17.894 18.228-6.023 6.126-12.057 12.24-18.078 18.367-8.877 9.037-17.75 18.078-26.62 27.122-5.905 6.021-11.815 12.038-17.689 18.091-0.728 0.749-1.038 0.269-1.504-0.206-12.475-12.736-24.947-25.473-37.435-38.196-8.176-8.33-16.375-16.635-24.561-24.956-2.187-2.222-4.344-4.475-6.556-6.673-0.542-0.539-0.642-0.929-0.092-1.489 3.02-3.075 6.029-6.163 9.041-9.246 0.59-0.604 0.905-0.053 1.256 0.304 2.831 2.872 5.655 5.75 8.489 8.619 4.853 4.914 9.754 9.782 14.561 14.74 9.092 9.379 18.265 18.677 27.467 27.948 3.321 3.346 6.59 6.743 9.827 10.169 0.723 0.765 1.09 0.556 1.704-0.076 7.49-7.695 14.995-15.376 22.514-23.042 5.007-5.105 10.053-10.171 15.061-15.273 8.927-9.095 17.84-18.203 26.756-27.308 1.79-1.827 3.593-3.644 5.343-5.51 0.532-0.567 0.892-0.645 1.451-0.062 2.324 2.421 4.679 4.812 7.019 7.217 0.162 0.167 0.299 0.358 0.406 0.488" fill="#fefefe" fill-rule="nonzero"/>
                    </g>
                    <g transform="translate(372.24 372.9)">
                        <path d="m0 11.073c-0.229-0.25-0.408-0.456-0.6-0.65-6.194-6.29-12.395-12.575-18.585-18.869-8.938-9.09-17.871-18.185-26.801-27.283-8.654-8.814-17.3-17.634-25.948-26.453-0.503-0.514-0.917-1.14-1.487-1.556-0.943-0.689-0.686-1.153 0.02-1.846 1.998-1.959 3.937-3.978 5.868-6.003 0.53-0.556 0.828-0.6 1.418 5e-3 6.019 6.18 12.082 12.32 18.128 18.475 6.078 6.186 12.15 12.378 18.229 18.564 5.908 6.012 11.829 12.011 17.729 18.032 9.535 9.73 19.05 19.48 28.594 29.201 0.593 0.604 0.582 0.92-0.02 1.531-12.82 13.007-25.616 26.038-38.412 39.068-8.626 8.784-17.245 17.575-25.865 26.365-0.951 0.969-1.914 1.93-2.807 2.952-0.516 0.59-0.884 0.654-1.451 0.07-2.839-2.923-5.681-5.845-8.581-8.706-0.68-0.67-0.316-0.983 0.135-1.437 2.258-2.267 4.511-4.54 6.756-6.819 9.031-9.162 18.063-18.323 27.085-27.494 8.656-8.798 17.299-17.606 25.947-26.411 0.217-0.223 0.413-0.468 0.648-0.736" fill="#fefefe" fill-rule="nonzero"/>
                    </g>
                </g>
            </g>
        </svg>
                    </span>
        </a>
        `;
    }
}

api.elements.define('converse-brand-logo', ConverseBrandLogo);
