import { checkBookmarksSupport } from '@converse/headless/plugins/bookmarks/utils';
import invokeMap from 'lodash-es/invokeMap';
import { Model } from '@converse/skeletor/src/model.js';
import { _converse, api, converse } from '@converse/headless/core';
import { __ } from 'i18n';


export function getHeadingButtons (view, buttons) {
    return buttons;
}

export function removeBookmarkViaEvent (ev) {
    ev.preventDefault();
    const name = ev.target.getAttribute('data-bookmark-name');
    const jid = ev.target.getAttribute('data-room-jid');
    if (confirm(__('Are you sure you want to leave the groupchat "%1$s"?', name))) {
        invokeMap(_converse.bookmarks.where({ jid }), Model.prototype.destroy);
    }
}

export async function addBookmarkViaEvent (ev) {
    ev.preventDefault();
    const jid = ev.target.getAttribute('data-room-jid');
    const room = await api.rooms.open(jid, { 'bring_to_foreground': true });
    room.session.save('view', converse.MUC.VIEWS.BOOKMARK);
}


export function openRoomViaEvent (ev) {
    ev.preventDefault();
    const { Strophe } = converse.env;
    const name = ev.target.textContent;
    const jid = ev.target.getAttribute('data-room-jid');
    const data = {
        'name': name || Strophe.unescapeNode(Strophe.getNodeFromJid(jid)) || jid
    };
    api.rooms.open(jid, data, true);
}
