import { __ } from 'i18n';
import { html } from "lit-html";
import { formatFingerprint } from '../utils.js';

const device_fingerprint = (el, device) => {
    if (device.get('bundle') && device.get('bundle').fingerprint) {
        return html`
            <li class="list-group-item">
                <form class="fingerprint-trust">
                    <code class="fingerprint">${formatFingerprint(device.get('bundle').fingerprint)}</code>
                </form>
            </li>
        `;
    } else {
        return ''
    }
}

export default (el) => {
    const i18n_fingerprints = __('Fingerprints');
    const i18n_no_devices = __("No devices found");
    const devices = el.devicelist.devices;
    return html`
        <hr/>
        <ul class="list-group fingerprints">
            <li class="list-group-item active"><strong>${i18n_fingerprints}</strong></li>
            ${ devices.length ?
                devices.map(device => device_fingerprint(el, device)) :
                html`<li class="list-group-item"> ${i18n_no_devices} </li>` }
        </ul>
    `;
}
