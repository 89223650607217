import { __ } from 'i18n';
import { html } from "lit";
import { modal_close_button, modal_header_close_button } from "plugins/modal/templates/buttons.js";


const subject = (o) => {
    const i18n_topic = __('Topic');
    const i18n_topic_author = __('Topic author');
    return html`
        <p class="room-info"><strong>${i18n_topic}</strong>: ${o.subject.text}</p>
        <p class="room-info"><strong>${i18n_topic_author}</strong>: ${o.subject && o.subject.author}</p>
    `;
}


export default (o) => {
    const i18n_name = __('Name');
    const i18n_online_users = __('Number of Participants');
   return html`
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header" id="muc-details-modal-header">
                    <h5 class="modal-title" id="muc-details-modal-label">Group Details</h5>
                    ${modal_header_close_button}
                </div>
                <div class="modal-body">
                    <span class="modal-alert"></span>
                    <div class="room-info">
                    <p class="muc-room-detail"><strong>${i18n_name}</strong>:&ensp; ${o.name}</p>
                    <p class="muc-room-detail"><strong>${i18n_online_users}</strong>:&ensp; ${o.num_occupants}</p>
                    </div>
                </div>
                <div class="modal-footer">${modal_close_button}</div>
            </div>
        </div>
    `;
}
