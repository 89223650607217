import DOMPurify from 'dompurify';
import { __ } from 'i18n';
import { api } from "@converse/headless/core";
import { html } from "lit-html";
import { modal_header_close_button } from "plugins/modal/templates/buttons.js"


export default (o) => {
    const i18n_modal_title = __('About Glacier');
    const i18n_about_glacier = __('About Glacier Desktop Beta');
    const show_client_info = api.settings.get('show_client_info');
    return html`
    <div class="about-page-writeup">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" id="user-details-modal-header">
                <h5 class="modal-title" id="about-modal-label">About Glacier Desktop Beta</h5>
                ${modal_header_close_button}
            </div>
            <div class="modal-body">
                <span class="modal-alert"></span>
                <div class="about__description">
                    <div class="about__description_writeup">
                        <p>Glacier provides the highest level of security for your team’s text, voice, and video calls.</p>
                        <p>Copyright 2017-2021, Glacier Security Inc<br>
                            Website: www.glacier.chat<br>
                            Contact: support@glacier.chat</p>
                        <p>This program is free software: you can redistribute it and/or modify it under the terms of the GNU General Public License as published by the Free Software Foundation, starting with either version 3 of the License, or (at your option) any later version. You can find the GNU General Public License here: https://www.gnu.org/licenses </p>
                        <p>This program is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU General Public License for more details. </p>
                        <p>Libraries used by Glacier Desktop include:<br>
                            &emsp;&emsp;https://github.com/angular/angular <br>
                            &emsp;&emsp;https://github.com/electron/electron<br>
                            &emsp;&emsp;https://github.com/conversejs/converse.js<br>
                        </p>
                        <p>Download the full source code at https://github.com/GlacierSecurityInc/glacierDesktop-pub </p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">${modal_close_button}</div>
            </div>
        </div>
    </div>
`};
