import BootstrapModal from "plugins/modal/base.js";
import tpl_image_modal from "./templates/image.js";


export default BootstrapModal.extend({
    id: 'image-modal',

    toHTML () {
        return tpl_image_modal({
            'src': this.src,
            'onload': ev => (ev.target.parentElement.style.height = `${ev.target.height}px`),
            'onclick': ev => `${this.saveImage(this.src)}`
        });
    },
    saveImage (link) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", link, true);
        xhr.responseType = "blob";
        xhr.onload = function(){
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(this.response);
          var tag = document.createElement('a');
          tag.href = imageUrl;
          tag.download = link;
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);
        }
        xhr.send();
    }
});
