import { html } from "lit";
import { api } from '@converse/headless/core.js';


export default () => html`
    <div class="inner-content converse-brand row">
        <div class="converse-brand__padding"></div>
        <div class="converse-brand__heading">
            <img src="logo/glacier.png" alt="Glacier Logo" style="width:200px;height:200px;"> 
            <span class="converse-brand__text">
                <span>Glacier<span class="subdued">Chat</span></span>
                 <p class="byline">BETA</p>
            </span>
        </div>
        ${ api.settings.get('view_mode') === 'overlayed' ? html`<div class="converse-brand__padding"></div>` : '' }
    </div>`;
