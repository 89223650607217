import avatar from 'shared/avatar/templates/avatar.js';
import { __ } from 'i18n';
import { html } from 'lit';
import { modal_close_button, modal_header_close_button } from 'plugins/modal/templates/buttons.js'


const remove_button = (o) => {
    const i18n_remove_contact = __('Remove as contact');
    return html`
        <button type="button" @click="${o.removeContact}" class="btn btn-danger remove-contact">
            <i class="far fa-trash-alt"></i>${i18n_remove_contact}
        </button>
    `;
}


export default (o) => {
    const i18n_contact_details = __('Contact Details');
    const i18n_address = __('XMPP Address');
    const i18n_email = __('Email');
    const i18n_full_name = __('Full Name');
    const i18n_nickname = __('Nickname');
    const i18n_profile = __('The User\'s Profile Image');
    const i18n_refresh = __('Refresh');
    const i18n_role = __('Role');
    const i18n_url = __('URL');
    const avatar_data = {
        'alt_text': i18n_profile,
        'extra_classes': 'mb-3',
        'height': '120',
        'width': '120'
    };

    const contactname = o.display_name;
    const contactusername = o.jid.split('@')[0];;

    return html`
    <div class="modal-dialog" role="document">
    <div class="modal-content">
    <div class="modal-header" id="user-details-modal-header">
    <div>
    <h5 class="modal-title" id="user-profile-modal-label">${i18n_contact_details}</h5>
    </div>
            ${modal_header_close_button}
        </div>
        <div class="modal-body">
        <div class="row">
        <div class="col-auto">
            ${ o.image ? html`<div class="mb-2">${avatar(Object.assign(o, avatar_data))}</div>` : '' }
        </div>
        <div class="col">
            <div class="form-group">
                <label class="col-form-label name-heading"><strong>${contactname}</strong></label>
                <div class="username-heading">@${contactusername}</div>
            </div>
        </div>
    </div>    
        

            <converse-omemo-fingerprints jid=${o.jid}></converse-omemo-fingerprints>
        </div>
        <div class="modal-footer">
            ${modal_close_button}
            <button type="button" class="btn btn-info refresh-contact">${i18n_refresh}</button>

        </div>
    </div>
</div>
    `;
}
